export default [
  {
    header: 'Займы',
  },
  {
    title: 'Дашборд',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Home',
    action: 'read',
  },
  {
    title: 'Звонки',
    route: 'phone-calls-list',
    icon: 'PhoneIcon',
    resource: 'Home',
    action: 'read',
  },
  {
    title: 'Заявки',
    route: 'applications-list',
    icon: 'CheckSquareIcon',
    resource: 'Application',
    action: 'read',
  },
  {
    title: 'Сделки',
    route: 'deals-list',
    icon: 'FileIcon',
    resource: 'Deal',
    action: 'read',
  },
  {
    title: 'Клиенты',
    route: 'clients-list',
    icon: 'UsersIcon',
    resource: 'Client',
    action: 'read',
  },
  {
    title: 'Уведомления',
    route: 'notifications-list',
    icon: 'BellIcon',
    resource: 'Notification',
    action: 'read',
  },
  {
    title: 'Контрольные даты',
    route: 'control-list',
    icon: 'CalendarIcon',
    resource: 'Deal',
    action: 'read',
  },
  {
    header: 'Collection',
  },
  {
    title: 'Платежи',
    route: 'payments-list',
    icon: 'DollarSignIcon',
    resource: 'Deal',
    action: 'read',
  },
  {
    title: 'Просрочка',
    route: 'delays-list',
    icon: 'FrownIcon',
    resource: 'Deal',
    action: 'read',
  },
  {
    header: 'Финансы',
  },
  {
    title: 'Инвестиции',
    route: 'investments-list',
    icon: 'BriefcaseIcon',
    resource: 'Deal',
    action: 'read',
  },
  {
    title: 'Статистика',
    route: 'statistics',
    icon: 'BarChart2Icon',
    resource: 'Deal',
    action: 'read',
  },
  {
    header: 'Админка',
  },
  {
    title: 'Брокеры',
    route: 'brokers-list',
    icon: 'MehIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Выгрузки',
    route: 'downloads',
    icon: 'DownloadIcon',
    resource: 'Deal',
    action: 'read',
  },
  {
    title: 'ПОД/ФТ',
    route: 'legal',
    icon: 'UsersIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Каталог авто',
    route: 'car-list',
    icon: 'TruckIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Кредитный Регистр',
    route: 'credit-register-list',
    icon: 'FileTextIcon',
    resource: 'User',
    action: 'read',
  },
]
